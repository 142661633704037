#card-notification {
    animation: pushMessage 2s infinite;
}
.frame-send-notification {
    width: 100%;
}
@keyframes pushMessage {
    50% {
        opacity: 0;
        transform: translateX(20%);
    }
    60%, 100% {
    opacity: 1;
    transform: translateX(0);
    }
}