.home-card {
  border-radius: 20px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.home-perc {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
}
.home-title {
  color: #d7eecc;
  font-size: 20px;
  text-align: left;
}
.count-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #2196f3;
}
.prem-button {
  max-width: 430px;
  width: 100%;
  height: 48px;
  border: 0ch;
  background:#f3900c;
  border-radius: 6px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 15%;
}

.prem-button:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  box-shadow: rgba(99, 99, 99, 0.5) 1px 1px 11px 1px;
}