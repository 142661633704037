/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap"); */

.App {
  text-align: center;
}

body {
  font-family: 'Nunito', sans-serif !important;
  max-width: 100vw;
}

.site-layout-background {
  background: #fff;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.text-center {
  text-align: center;
}
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.ant-form-item-extra {
  font-size: small !important;
}
html {
  font-size: 100%;

   /* tablet {
      font-size: 80%;
  }

  @include mobile {
      font-size: 60%;
  } */
}
@media (max-width: 400px) {
  html {
    font-size: 60%;
  }
}
@media (max-width: 800px) {
  html {
    font-size: 80%;
  }
}