@use '../scss/' as *;

.margin-auto {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  @include tablet {
    padding: 0 24px;
  }
}
button {
  padding: 0.5rem 1rem;
  height: 48px;
  border-radius: $border-radius;
  border: none;
  font-weight: 500;
  transition: all .2s ease-in-out;
  font-size: 17px;
}
.relative {
  position: relative;
}
.section-blue {
  background-color: $main-color;
}
.section-white {
  background-color: $white-light;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.bg-intro {
  display: grid;
  height: 85vh;
  grid-template-columns: 50% 50%;
  z-index: 30;
}
.intro-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  padding: 0 2rem;
  margin-bottom: 46px;
  text-align: start;
  @include tablet {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;
    justify-items: center;
    align-items: center;
  }
}
.discovery-container {
  @include tablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  @include mobile {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;
  }
}
.intro-app {
  height: 85vh;
  @include laptop {
    height: 70vh;
  }

  @include tablet {
    height: fit-content;
  }
}
.intro-left {
  text-align: start;
  & h2 {
    font-size: 40px;
    margin-top: 2rem;
  }
  & p {
    color: $gray-black;
    margin-top: 1rem;
    font-size: 18px;
  }
  & .intro-button-container {
    margin-top: 3rem;
  }
}

.intro-right {
  width: 100%;
}
.intro-btn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 4rem;
}

.hidden-on-tablet {
  @include tablet {
    display: none;
  }
}

.txt-white {
  color: $white-text;
}
.txt-blue {
  color: $main-color;
}


.txt-28 {
  font-size: 28px;
  @include tablet {
    font-size: 24px;
  }
}
.txt-32 {
  font-size: 32px;
  @include tablet {
    font-size: 28px;
  }
}
.txt-24 {
  font-size: 24px;
  @include tablet {
    font-size: 19px;
  }
}
.txt-18 {
  font-size: 18px;
  @include tablet {
    font-size: 17px;
  }
}
.txt-16 {
  font-size: 16px;
  @include tablet {
    font-size: 15px;
  }
}

.customer-comment-title {
  font-weight: 700;
  color: #0059BE;
}
.txt-46 {
  font-size: 46px;
  @include tablet {
    font-size: 32px;
  }
  @include mobile {
    font-size: 28px;
  }
}

.specific-item-container {
  padding: 0 1.5rem;
  @include tablet {
    text-align: left;
  }
}

.discovery-wrapper {
  margin: 100px 0;
  justify-content: center;
  justify-items: center;
  justify-self: center;
}
.boxed {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: $border-radius;
  font-size: 22px;
  position: relative;
  margin-bottom: 16px;
}
.boxed:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background-color: currentColor;
  opacity: 0.1;
}
.client-comment {
  margin: 2rem 0;
  padding: 0 6rem;
  @include tablet {
    padding: 0 4rem;
  }
  &__title {
    font-size: 24px;
  }
  &__message {
    font-size: 18px;
    margin: 2rem 0;
    color: #374040;
  }
  &__profile-container {
    display: flex;
    justify-content: center;
    text-align: center;
    align-content: center;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    & img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
.client-name {
  margin-left: 2rem;
  padding-left: 2rem;
  border-left: 3px solid #484848;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.list-plan {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 320px), 1fr));
  grid-gap: 4rem;
  margin-top: 100px;
  padding: 0 1rem;
  @include tablet {
    padding: 0 1.5rem;
  }
}
.plan-header {
  display: flex;
  padding-top: 1.5rem;
  text-align: start;
  &__icon {
    margin-right: 1rem;
  }
  &__price {
    margin-bottom: 1rem;
    & .get-in-touch {
      text-align: center;
      color: $main-color;
    }
  }
}

.impression {
  background-color: $main-color-light;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.plan-item {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  transition: all 0.5s ease-out;
  &:hover {
    transform: translateY(-20px);
    cursor: pointer;
  }
}
.plan-item-selected {
  border: 2px solid $main-color;
}
.padding-lr {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.flex-start {
  justify-content: start;
  display: block;
}
.connect-shopify-title {
  letter-spacing: 0.25rem;
  color: $white;
  margin-top: 45px;
  margin-bottom: 45px;
}
.intro-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  @include tablet {
    padding: 2rem 0 1.5rem 0;
    order: 1;
  }
}
.intro-left-title {
  @include tablet {
    padding: 0 0 1.5rem 0;
    order: 2;
  }
}
.quote-header {
  margin-top: 8rem;
  text-align: center;
  padding: 4rem 0;
  
  &-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
}
.boxed-quote {
  height: 80px;
  width: 80px;
}
.icon-quote {
color: $main-color;
}
.plan-container {
  font-weight: 600;
  color: $main-color;
}
.box-tag {
  padding: 0.5rem;
  border-radius: 10px;
  color: $main-color;
  background: $main-color-light;
}
.plan-tag-container {
  margin-top: 8rem;
  margin-bottom: 1rem;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit;
  left: 10px;
  z-index: 2;
  color: $main-color;
  font-size: 20px;

   width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  &:hover {
    background: #8CC3FF;
  }
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  right: 10px;
  z-index: 2;
  color: $main-color;
  font-size: 20px;
}
.ant-carousel .slick-dots li button{
  width: 10px;
    height: 10px;
  border-radius: 100%;
  background: $main-color;
}
.ant-carousel .slick-dots li.slick-active button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: $main-color;
}
.exclusive-btn-container {
  margin-bottom: 2rem;
  margin-top: 12px;
  padding: 0 24px;
}
.rocket-quote__bottom {
  position: absolute;
  top: 0;
  left: 25%;
  @include tablet {
    display: none;
  }
}
.rocket-lg {
  width: 130px;
  height: 130px;
  padding: 20px;
}
.btn-primary {
  background: $main-color;
  color: $white-text;
  border: 1px solid $main-color;
  &:hover {
    scale: 1.05;
    background-color: $main-color-light;
    color: $main-color;
    cursor: pointer;
  }
}

.btn-secondary {
  color: $main-color;
  background: $main-color-light;
  border: 1px solid $main-color-light;
  &:hover {
    scale: 1.05;
    box-shadow: $box-shadow-light;
    color: $main-color;
    background: white;
    cursor: pointer;
  }
}
.btn-primary-outline {
  color: $main-color;
  background: $main-color-light;
  transition: all 0.3s;
  border: 1px solid $main-color-light;
  &:hover {
    scale: 1.01;
    background-color: $main-color-light;
    color: $main-color;
    cursor: pointer;
    // border: 1px solid $main-color;
  }
}
.bg-icon {
  background-position: bottom right;
    background-size: auto 100%;
    background-repeat: no-repeat;
}
.div-center {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 56px;
}

.video-intro-section {
  position: relative;
  padding: 64px 0;
  background-color: #8CC3FF;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.align-space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.video-intro {
  width: 70%;
  max-width: 860px;
  z-index: 2;
  @include mobile {
    width: 80%;
  }
}
.discovery-title {
  margin: 8px 0 ;
  font-weight: 900;
  font-size: 32px;
  color: $main-color;
}
.intro-img {
  max-width: 376px;
  max-height: 376px;
  @include tablet {
    max-width: 100%;
    max-height: 100%;
  }
 
}
.discovery-card {
  gap: 100px  16px;
  @include tablet {
    gap: 0;
    background: white;
    box-shadow: $box-shadow;
    border-radius: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.border-corner {
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
}
.get-shopify-container {
  padding: 40px 0;
}
.second-video-intro {
  padding: 128px 0;
  margin-top: 64px;
  @include mobile {
    padding: 24px 0;
    margin-top: 4px;
  }
}
.short-line {
  height: 4px;
  width: 50px;
  background: $main-color;
  margin-bottom: 8px;
}
.noti-gif {
  position: absolute;
}
.laptop-frame {
  position: absolute;
}
.gif-into {
  width: 100%;
  height: 100%;
  min-height: 400px;
}
.fullscreen-animation {
  width: 100%;
  height: auto;
  @include laptop {
    display: none;
   }
}
.bg-intro-right {
  display: flex;
  justify-items: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  @include tablet {
    display: none;
  }
}

.show {
 opacity: 1;
 filter: blur(0);
 transform: translateX(0);
 transition: all 1s;
}
.hidden-left {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}
.hidden-right {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(200%);
  transition: all 1s;
}
.overflow-hidden {
  overflow-x: hidden;
}
.rocket-icon {
  width: 100%;
  height: 100%;
}
.rocket-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

}
.container-rocket-icon {
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 300px;
  height: 300px;
  z-index: 1;
}


.card-float {
  margin-bottom: -30px; 
  width: auto;
  height: auto;
  background-position: left top;
}
.start-position {
  display: flex;
  margin-left: 300px;
}
.end-position {
  display: flex;
  justify-content: end;
  margin-right: 300px;
  margin-top: -30px;
  // margin-left: 300px;
}
.bottom-sky {
  width: 100%;
  height: 290px;
  margin-top: -180px;
  @include tablet {
    margin-top: -48px;
  }
}
.section-video {
  margin-top: -8px;
}
.rocket-blur-right {
  position: absolute;
  bottom: 30%;
  right: 50px;
  z-index: -1;
  transform: rotate(-90deg);
  & img {
    width: 300px;
    height: 300px;
  }
}
.rocket-blur-left {
  position: absolute;
  bottom: 0;
  left: 50px;
  z-index: -1;
  & img {
    width: 220px;
    height: 220px;
  }
}

.bg-gradient {
  background: linear-gradient(90deg, rgba(0,73,170,1) 0%, rgba(15,115,221,1) 50%, rgba(0,73,170,1) 100%);
}
.discovery-item-img-left {
  justify-content: end;
  display: flex;
}
.margin-right {
  margin-right: 24px;
}
.margin-left {
  margin-left: 24px;
}
.bg-sky-white {
  background-position: bottom left;
  background-repeat: no-repeat;
  width: 100%;
  overflow-x: hidden;
  height: 280px;
  margin-top: -120px;
}
.img-full {
  height: 100%;
   
}
.icon-container {
  border: 2px solid $main-color;
  width: 21px;
  height: 21px;
  &:hover {
    cursor: pointer;
  }
}
.gif-mobile {
  display: flex;
  justify-items: center;
  align-items: center;
  height: 45vw;
  // height: fit-content;
  overflow: hidden;
  width: 80vw;
  display: none;
  @include tablet {
    display: flex;
  }
}
.bg-laptop {
  @include tablet {
    display: none;
  }
}

.title-discovery {
  font-size: 24px;
  font-weight: 600px;
  ;
  @include tablet {
    font-size: 19px;
  }
}
.description-discovery {
  font-size: 18px;
  color: $gray-black;
  @include tablet {
    font-size: 14px;
  }
}
.bg-icon-white-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  & img {
    width: 100%;
    height: 100%;
  }
}
.exclusive-container {
  margin-top: 16px;
  margin-bottom: 16px;
}

h3,h2{
  margin-top: 20px;
}
.btn-full-mobile {
  @include tablet {
    width: 100%;
  }
}