$body-bg: #0f0f0f;
$main-color: #0d83dd;
$gray-black: #374040;
$main-color-light: #DFF1FF;
$white-text: #f1f0f0;
$white: #fff;
$black: #000;
$white-light: #DFF1FF;
$gray-light: #778484;
$box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$box-shadow-light: 0 0 3px rgb(0 0 0 / 10%);
$header-height: 8rem;
$header-shrink-height: 5rem;
$border-radius: 10px;

$mobile-width: 600px;
$tablet-width: 1024px;
$full-screen: 1280px;
$font-family: "Roboto", sans-serif;
