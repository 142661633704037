@use '../scss/' as *;
.margin-auto {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    @include tablet {
    //   padding: 0 24px;
    padding: 0;
    }
  }
.footer-container {
    padding-top: 100px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    @include tablet {
        grid-template-columns: 1fr 1fr;
    }
    @include mobile {
        grid-template-columns: 1fr;
    }
}
.footer-item {
    padding-top: 24px;
    & h4 {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 32px;
    }
    & a {
        font-size: 14px;
        color: $gray-light;
        @include mobile {
            font-size: 12px;
        }
    }
}
.footer-logo {
    width: 180px;
    height: 120px;
    @include mobile {
        max-width: 130px;
        height: 120px;
    }
}
.info-email {
    color: $main-color !important;
    font-size: 13px;
    text-decoration: underline;
    font-weight: bold;
}
            
.info-title {
    padding: 0 0 8px 0;
    font-weight: bold;
    color: $gray-light;
}
.footer-legal {
    text-align: center;
    & p {
        color: $gray-light;
        padding: 16px;
       border-top: 1px solid #e4ebf3;
    }
    & hr {
        color: $gray-light;
    }
}
