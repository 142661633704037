@use '../scss/' as *;
.video-intro {
    width: 100%;
    max-width: 860px;
    z-index: 2;
    border-width: 0px !important;
    color: $main-color;
    border-radius: 10px;
    &:hover {
      color: $main-color-light;
    }
    @include mobile {
    //   width: 80%;
    }
  }