@use '../scss/' as *;
$secondary-red: #db3951;
$secondary-blue: #1890ff;
$secondary-black: #222222;

header {
  display: flex;
  height: 80px;
  padding: 0 2rem;

  z-index: 99;
  font-weight: 500;
  font-size: 17px;
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.3s;
  font-family: 'Nunito';
  & .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
  }
  & nav {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  }
}

nav span a {
  margin: 0 1rem;
  text-decoration: none;
  color: $secondary-black !important;
  &:hover {
    color:$main-color !important;
  }
  @include tablet {
    color: $white-text !important;
  }
}

nav span:hover {
  color: $main-color;
}

// .header-mobile {
//   display: flex;
//   justify-content: space-between;
// }
.logo-main {
  opacity: 1;
  visibility: visible;
  & a {
    color: $secondary-black !important;
  }
}
// .logo-main a {
//   color: $secondary-black !important;
// }
.item-selected {
  text-decoration: underline;
  text-decoration-thickness: 3px;
}
.header-container-mobile {
  display: flex;
  justify-content: space-between;
  display: none;
  opacity: 0;
  align-items: center;
}
.menu-hamberger-icon {
  color: $main-color;
}
.nav-close-btn {
  color: $white-text;
}
@media only screen and (max-width: 1024px) {
  header {
    justify-content: flex-end;
  }
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: $main-color;
    transition: 1s;
    z-index: 99;
    transform: translateY(-100vh);
    
  }
  header .responsive_nav {
    transform: none;
  }
  nav .nav-close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  nav span {
    font-size: 18px;
  }
  .logo-main {
    opacity: 0;
    visibility: hidden;
  }
  .header-container-mobile {
    visibility: visible;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .info-tour-pc {
    display: none;
  }
}
.nav-logo-mobile {
  display: flex;
  align-items: center;
  & a {
    color: $secondary-black;
  }
}
.logo {
    width: 45px;
    height: 45px;
    margin-right: 8px;
}